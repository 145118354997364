import Vue from 'vue'
import Vuex from 'vuex'
import $axios from '@/plugins/axios'
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    categories: [],
    products: [],
    product: {},
    orders: [],
    order: {},
    colors:[],
  },
  getters: {
    getProducts(state) {
      return state.products
    },
    getProduct(state) {
      return state.product
    },
    getCategories(state) {
      return state.categories
    },
    getOrders(state) {
      return state.orders
    },
    getOrder(state) {
      return state.order
    },
    getColors(state) {
      return state.colors
    }
  },
  mutations: {
    setProducts(state, products) {
      state.products = products
    },
    setProduct(state, product) {
      state.product = product
    },
    setCategories(state, categories) {
      state.categories = categories
    },
    setToken(state, token) {
      state.token = token
    },
    setUser(state, user) {
      state.user = user
    },
    setOrders(state, orders) {
      state.orders = orders
    },
    setOrder(state, order) {
      state.order = order
    },
    setColors(state, colors) {
      state.colors = colors
    }
  },
  actions: {
    // Auth
    login({ commit }, payload) {
      return $axios.post('email-login', payload).then((res) => {
        commit('setUser', res.data.user)
        router.push({ name: 'dashboard' })
      })
    },

    logout({ commit }) {
      return $axios.post('logout').then((res) => {
        commit('setToken', null)
        commit('setUser', null)
      })
    },

    register({ commit }, payload) {
      return $axios.post('register', payload).then((res) => {
        commit('setToken', res.data.access_token)
        commit('setUser', res.data.user)
      })
    },

    // Products
    getProducts({ commit }, search) {
      let page = search.page ?? 1 
      return $axios.get(`admin/products?page=${page}&sort=${search.sort}`).then((res) => {
        commit('setProducts', res.data)
      })
    },

    fetchProducts({ commit }, search) {
      return $axios.get(`admin/products?page=${search.page}&category=${search.category}&sort=${search.sort}&title=${search.keyword}`).then((res) => {
        commit('setProducts', res.data)
      })
    },

    showProduct({ commit }, id) {
      return $axios.get(`admin/products/${id}`)
    },

    saveProduct({ commit }, payload) {
      return $axios.post('admin/products', payload)
    },

    updateProduct({ commit }, payload) {

      if (payload.categories[0].id) {
        payload.categories = payload.categories.map((category) => {
          return category.id
        })
      }

      return $axios.patch(`admin/products/${payload.id}`, payload)
    },

    deleteProduct({ commit }, payload) {
      return $axios.delete(`admin/products/${payload}`)
    },

    saveVariations({ commit }, payload) {
      return $axios.post(`admin/products/${payload.id}/variations`, payload.variations)
    },

    updateVariations({ commit }, payload) {
      return $axios.patch(`admin/variations/${payload.id}`, payload.variations)
    },

    deleteVariation({ commit }, id) {
      return $axios.delete(`admin/variations/${id}`)
    },

    saveCategory({ commit }, payload) {
      return $axios.post('admin/categories', payload)
    },

    updateCategory({ commit }, payload) {
      return $axios.patch(`admin/categories/${payload.id}`, payload)
    },

    deleteCategory({ commit }, id) {
      return $axios.delete(`admin/categories/${id}`)
    },

    // upload image for Product
    uploadImage({ commit }, payload) {
      return $axios.post(`admin/image`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },

    deleteImage({ commit }, url) {
      return $axios.delete(`admin/image?address=${url}`)
    },

    // Categories
    getCategories({ commit }) {
      return $axios.get('admin/categories').then((res) => {
        commit('setCategories', res.data)
      })
    },

    // Orders
    getOrders({ commit }, page = 1) {
      return $axios.get(`admin/orders?page=${page}`).then((res) => {
        commit('setOrders', res.data)
      })
    },
    fetchOrder({ commit }, uuid) {
      return $axios.get(`admin/orders/uuid/${uuid}`).then((res) => {
        commit('setOrder', res.data.data)
      })
    },
    updateOrder({ commit }, payload) {
      return $axios.patch(`admin/orders/${payload.uuid}`, payload)
    },

    // Colors
    fetchColors({ commit }) {
      return $axios.get(`admin/colors`).then((res) => {
        commit('setColors', res.data.data)
      })
    },

    // Payments
    getPayments({ commit }) {
      return $axios.get('admin/payments')
    },

    // Users
    getUsers({ commit }) {
      return $axios.get('admin/users')
    },
  },
  modules: {},
})
