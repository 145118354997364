<template>

  <div>

    <nav-menu :menu="categories" @selected="(cat) => category = cat" />

    <v-data-table
      :headers="headers"
      :items="products"
      sort-by="calories"
      class="elevation-1"
      :loading="loading"
      :items-per-page="40"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- if category is empty, show text, else show category  -->
          <v-toolbar-title v-if="!category">محصولات</v-toolbar-title>
          <v-toolbar-title v-else>دسته {{ category }}</v-toolbar-title>

          <v-spacer />

          <search-bar @search="(searchKeyword) => keyword = searchKeyword"/>  
            <v-spacer />

          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> محصول جدید </v-btn>
            </template>
            <product-form @close="close" :item-id="editedItem.id" />
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card class="pa-4 d-flex justify-space-between align-center">
              <h4>حذف محصول</h4>
              <div class="d-flex justify-end">
                <v-btn color="primary" class="mx-2" text @click="dialogDelete = false">بیخیال</v-btn>
                <v-btn color="error" @click="deleteProduct" :loading="loading">حذف</v-btn>
              </div>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.code`]="{ item }">
        <div class="p-2" v-if="!item.categories.length">
          <span class="red-circle" />
        </div>
      </template>

      <template v-slot:[`item.title`]="{ item }">
        <a :href="uiUrl +`${item.slug}`" target="_blank">
          <div class="p-2">{{ item.title }}</div>
        </a>

        <!-- <div class="p-2">{{ item.title }}</div> -->
      </template>

      <template v-slot:[`item.thumbnail`]="{ item }">
        <div class="p-2">
          <v-img :src="item.thumbnail" :alt="item.name" height="100px" width="100px"></v-img>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="primary" icon @click="editItem(item)"><v-icon>mdi-text-box-edit</v-icon></v-btn>
        <v-btn color="error" icon @click="deleteItem(item)"><v-icon> mdi-delete </v-icon></v-btn>
      </template>

      <template v-slot:[`footer`]>
        <div class="pa-4">
          <v-pagination v-model="page" :length="productsMeta?.last_page" total-visible="8" />
        </div>
      </template>

      <template v-slot:no-data>
        <v-btn color="primary"> Reset </v-btn>
      </template>
    </v-data-table>


  </div>

</template>

<script>
  import { mapGetters } from 'vuex'
  import ProductForm from '../components/ProductForm.vue'
  import ProductUploadImage from '../components/ProductUploadImage.vue'
  import SearchBar from '../components/SearchBar.vue'
  import NavMenu from "../components/NavMenu.vue";

export default {
  name: 'ProductsView',

  data: () => ({
    uiUrl: process.env.VUE_APP_UI_URL + '/product/',
    dialog: false,
    dialogDelete: false,
    dialogUpload: false,
    deleteItemId: '',
    loading: true,
    page: 1,
    category: null,
    keyword: null,
    headers: [
      { text: '', value: 'code' },
      {
        text: 'عنوان',
        align: 'start',
        sortable: false,
        value: 'title',
      },
      { text: 'اسلاگ', value: 'slug' },
      { text: 'تنوع ها', value: 'variation_count' },
      { text: 'میانگین قیمت', value: 'price' },
      { text: 'ابعاد', value: 'dimensions' },
      { text: 'تصاویر', value: 'thumbnail' },
      { text: '', value: 'actions', sortable: false, align: 'left' },
    ],
    item: {
      id: '',
      title: '',
      slug: '',
      description: '',
      price: 0,
      categories: [],
    },
    editedIndex: -1,
    editedItem: {
      id: '',
      title: '',
      slug: '',
      description: '',
      price: 0,
      categories: [],
    },
    defaultItem: {
      id: '',
      title: '',
      slug: '',
      description: '',
      price: 0,
      categories: 1,
    },
  }),

  components: {
    ProductForm,
    ProductUploadImage,
    SearchBar,
    NavMenu,
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },

    ...mapGetters({
      productsList: 'getProducts',
      categories: 'getCategories',
    }),

    products() {
      return this.productsList.data
    },

    productsMeta() {
      return this.productsList.meta
    },
    search() {
      return {
        keyword: this.keyword,
        category: this.category,
        page: this.page,
        sort: 'oldest' // TODO : this can be dynamic -  or 'by_title' 
        }
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },

    dialogDelete(val) {
      val || this.closeDelete()
    },

    page(val) {
      this.searchProducts()
    },

    keyword(val){
      this.searchProducts()
    },
    
    category(val){
      this.searchProducts()
    },

  },

  methods: {
    editItem(item) {
      this.editedIndex = this.products.indexOf(item).id
      this.editedItem = Object.assign({}, item)
      this.dialog = true      
    },

    uploadImage(item) {
      this.editedIndex = this.products.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogUpload = true
    },

    deleteItem(item) {
      this.dialogDelete = true
      this.deleteItemId = item.id
    },

    deleteProduct() {
      this.$store
        .dispatch('deleteProduct', this.deleteItemId)
        .then((res) => {
          this.refreshTable()
        })
        .finally(() => {
          this.dialogDelete = false
        })
    },

    deleteItemConfirm() {
      this.products.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1

        // re-render the table
        this.refreshTable()
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeUpload() {
      this.dialogUpload = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.products[this.editedIndex], this.editedItem)
      } else {
        this.products.push(this.editedItem)
      }
      this.close()
    },

    handleClick(id) {
      this.$router.push({ name: 'product', params: { id: id } })
    },

    searchProducts() {
      this.loading = true
      const container = document.querySelector('.admin__main')
      container.scroll({ top: 0, left: 0, behavior: 'smooth' })
      this.$store.dispatch('fetchProducts', this.search).finally(() => {
        this.loading = false
      })
    },

    refreshTable() {
      this.loading = true
      this.$store.dispatch('fetchProducts', this.search).finally(() => {
        this.loading = false
      })
    },
  },

  mounted() {
    this.$store.dispatch('getProducts', this.search).finally(() => {
      this.loading = false
    })
    this.$store.dispatch('getCategories')
  },
}
</script>